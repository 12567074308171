import React from 'react'
import { HydraAdmin } from '@api-platform/admin'
import auth from './providers/auth'
import data from './providers/data'
import i18n from './providers/i18n'
import UsersResource from './resources/Users'
import CompanyCouriersResource from './resources/CompanyCouriers'
import IndependentCouriersResource from './resources/IndependentCouriers'
import ManagersResource from './resources/Managers'
import FreightForwardersResource from './resources/FreightForwarders'
import VehiclesResource from './resources/Vehicles'
import CompaniesResource from './resources/Companies'
import CompaniesCustomPagesResource from './resources/CompaniesCustomPages'
import DeliveriesResource from './resources/Deliveries'
import DeliveriesTemplatesResource from './resources/DeliveriesTemplates'
import RidesResource from './resources/Rides'
import PriceSpecificationResource from './resources/PriceSpecification'
import DepartmentResource from './resources/Department'
import TenderResource from './resources/Tender'
import HubResource from './resources/Hubs'
import ZoneResource from './resources/Zone'
import CompanyDepartmentAssignationsResource from "./resources/CompanyDepartmentAssignations";
import theme from './theme'

// See more, https://api-platform.com/docs/admin/authentication-support
const App = () => (
  <HydraAdmin
    entrypoint={`${process.env.REACT_APP_API_SERVER}/api`}
    authProvider={auth}
    dataProvider={data}
    i18nProvider={i18n}
    theme={theme}
  >
    <VehiclesResource name='vehicles' options={{ label: 'Véhicules' }} />
    <CompaniesResource name='companies' options={{ label: 'Compagnies' }} />
    <CompanyDepartmentAssignationsResource name='company_department_assignations' options={{ label: 'Compagnies-Département' }} />
    <CompaniesCustomPagesResource name='custom_pages' options={{ label: 'Formulaires personnalisés' }} />
    <UsersResource name='users' options={{ label: 'Utilisateurs' }} />
    <CompanyCouriersResource name='company_couriers' options={{ label: 'Coursiers salariés' }} />
    <IndependentCouriersResource name='independent_couriers' options={{ label: 'Coursiers indépendants' }} />
    <ManagersResource name='managers' options={{ label: 'Gérants' }} />
    <FreightForwardersResource name='freight_forwarders' options={{ label: 'Commissionaires' }} />
    <DeliveriesResource name='deliveries' options={{ label: 'Courses' }} />
    <DeliveriesTemplatesResource name='delivery_templates' options={{ label: 'Courses favorites' }} />
    <RidesResource name='rides' options={{ label: 'Retours à vide' }} />
    <PriceSpecificationResource name='price_specifications' options={{ label: 'Grilles tarifaires' }} />
    <ZoneResource name='zones' options={{ label: 'Listes de départements' }} />
    <DepartmentResource name='departments' options={{ label: 'Départements' }} />
    <TenderResource name='tenders' options={{ label: 'Appels d\'offres' }} />
    <HubResource name='hubs' options={{ label: 'Hubs' }} />
  </HydraAdmin>
)

export default App
