import React from 'react'
import {EditGuesser, InputGuesser} from '@api-platform/admin'
import PriceInput from '../../components/PriceInput'
import TailgateGuaranteedInput from "../../components/TailgateGuaranteedInput";
import { ReferenceInput, SelectInput } from 'react-admin'
import PriceSpecificationProposalInput from "../../components/PriceSpecificationProposalInput";

const Edit = ({...props}) => (
    <EditGuesser {...props}>
        <InputGuesser source={'name'} label={"Nom"}/>
        <InputGuesser source={'description'}/>

        <InputGuesser source={'allowance1MaxMileage'} label={"Distance 1 km max"}/>
        <PriceInput source={'allowance1FlatPrice'} label={"Distance 1 montant"}/>

        <InputGuesser source={'allowance2MaxMileage'} label={"Distance 2 km max"}/>
        <PriceInput source={'allowance2FlatPrice'} label={"Distance 2 montant"}/>

        <InputGuesser source={'allowance3MaxMileage'} label={"Distance 3 km max"}/>
        <PriceInput source={'allowance3FlatPrice'} label={"Distance 3 montant"}/>

        <InputGuesser source={'allowance4MaxMileage'} label={"Distance moyenne km max"}/>
        <InputGuesser source={'allowance4Rate'} label={"Distance moyenne taux km"}/>

        <InputGuesser source={'allowance5MaxMileage'} label={"Longue distance km max"}/>
        <PriceInput source={'allowance5FlatPrice'} label={"Longue distance montant"}/>

        <InputGuesser source={'allowance6Rate'} label={"Très longue distance taux km"}/>
        <PriceInput source={'allowance6CeilingPrice'} label={"Très longue distance montant plafond"}/>

        <PriceInput source={'flatPrice'} label={"Forfait fixe (prix unique)"}/>

        <InputGuesser source={'pickupTimeLimit'} label={"Temps limite pour prix garanti (minutes)"}/>

        <TailgateGuaranteedInput source={'tailgateGuaranteed'} label={"Prix garanti avec hayon"}/>

        <InputGuesser source={'notWorkingDayRate'} label={"Taux pour les jours non ouvrés (weekend)"}/>

        <ReferenceInput source={'eveningDepartment'} reference={'zones'} label={'Départements pour la soirée (16h30 à 18h00)'} allowEmpty>
            <SelectInput optionText="name" />
        </ReferenceInput>

        <PriceSpecificationProposalInput source={'proposal'} label={"Propositions"} />

        <InputGuesser source={'fastProposalShortRangeRate'} label={"Proposition rapide : taux entre Dis. 3 et Longue dis."}/>
        <InputGuesser source={'fastProposalLongRangeRate'} label={"Proposition rapide : taux pour Longue dis. et +"}/>

        <InputGuesser source={'postalCodesNotGuaranteed'} multiline={true} fullWidth={true} label={"Villes non desservies via Glinko en France"} helperText={"Format : […],[code postal],[…] Exemple : 44300,76000 Le tarif garanti ne sera pas disponible pour les courses depuis et/ou vers ces codes postaux."} />
    </EditGuesser>
)

export default Edit
