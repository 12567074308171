import React from 'react'
import { ListGuesser, FieldGuesser } from '@api-platform/admin'
import { AutocompleteInput, DateField, Filter, Pagination, ReferenceField, ReferenceInput, TextField, TextInput  } from 'react-admin'
import AddressField from '../../components/AddressField'
import ArrayStringField from '../../components/ArrayStringField'
import IdField from '../../components/IdField'
import RideTypeField from "../../components/RideTypeField";
import FilterCompaniesByName from "../../components/filters/FilterCompaniesByName";
import CoordinatesField from "../../components/CoordinatesField";
import TruncatedTextField from '../../components/TruncatedTextField'

const FromDateTimeField = ({ record }) => (
  <DateField
    record={record.start}
    source='fromDatetime'
    showTime
  />
)

const RidePagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100, 250]} {...props} />;

const RideFilter = (props) => (
    <Filter {...props}>
        <TextInput source="name" label={"Nom"} alwaysOn />

        <ReferenceInput label="Compagnie" source="company" reference="companies"
                        filterToQuery={FilterCompaniesByName} allowEmpty alwaysOn>
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
    </Filter>
);

const List = ({ ...props }) => (
  <ListGuesser {...props} perPage={250} pagination={<RidePagination />} filters={<RideFilter />} sort={{ field: 'id', order: 'DESC' }}>
    <IdField source={'id'} />
    <FieldGuesser source={'name'} label={"Nom"} />
    <FieldGuesser source={'vehicleType'} label={"Type de véhicule"} />
    <RideTypeField source={"rideType"} label={"Type"} />
    <ReferenceField source={'company'} reference='companies' label={"Compagnie"} >
      <TextField source='name' />
    </ReferenceField>
    <FromDateTimeField label='Date et heure de départ' />
    <AddressField source={'start'} label={"Départ"} />
    <CoordinatesField source={'start'} label={"Départ (coordonnées)"} />
    <AddressField source={'finish'} label={"Arrivée"} />
    <CoordinatesField source={'finish'} label={"Arrivée (coordonnées)"} />
    <ArrayStringField source={'options'} label={"Options"}  />
    <ArrayStringField source={'days'} label={"Jours"} />
    <TruncatedTextField source={'comment'} label={"Commentaire"} />
  </ListGuesser>
)

export default List
